<template>
  <m-sidebar-card
    :scope="scope"
    :on-save="save"
    :on-destroy="destroy"
    :saving="$wait.is('saving space')"
    :destroying="$wait.is('destroying space')"
    :is-new="isNew"
    :title="title"
  >
    <v-text-field
      ref="spaceAddressRef"
      v-model="space.address"
      v-validate="{
        required: true,
      }"
      :error-messages="errors.collect('address', scope)"
      data-vv-validate-on="blur"
      :label="spaceNamePlaceholder"
      name="space.address"
      outlined
    />

    <template v-slot:footer>
      <m-btn-help
        absolute
        :style="{
          right: '24px',
        }"
        class="mt-3"
        @click="() => startTour('MSpaceEditor')"
      />
    </template>
  </m-sidebar-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import cloneDeep from 'lodash.clonedeep';
  import { mapWaitingActions } from 'vue-wait';
  import MSidebarCard from './MSidebarCard';
  import { tour } from '@/mixins/tour';

  export default {
    $_veeValidate: { validator: 'new' },

    components: {
      MSidebarCard,
    },

    mixins: [
      tour,
    ],

    data: () => ({
      space: {},
      scope: 'space',
    }),

    computed: {
      ...mapGetters({
        props: 'sidePanel/props',
        spaceById: 'projectEditor/spaces/spaceById',
      }),

      spaceId() {
        return this.props.spaceId;
      },

      isNew() {
        return this.spaceId === null;
      },

      title() {
        if (this.isNew) {
          return this.$pgettext('MSpaceEditor component title', 'New space');
        }

        return this.$pgettext('MSpaceEditor component title', 'Edit space');
      },

      onAfterSave() {
        if (!this.props.onAfterSave) return () => {};

        return this.props.onAfterSave;
      },

      spaceNamePlaceholder() {
        return this.$pgettext('MSpaceEditor component text input placeholder', 'Space name');
      },
    },

    created() {
      this.initSpace();
    },

    methods: {
      ...mapActions({
        closeSidePanel: 'sidePanel/closeSidePanel',
      }),

      ...mapWaitingActions('projectEditor/spaces', {
        createSpace: 'saving space',
        updateSpace: 'saving space',
        destroySpace: 'destroying space',
      }),

      initSpace() {
        if (this.isNew) return;

        this.space = cloneDeep(this.spaceById(this.spaceId));
      },

      async save() {
        const valid = await this.$validator.validateAll(this.scope);

        if (!valid) return;

        if (this.isNew) {
          await this.create();
          this.reset();
        } else {
          await this.update();
        }

        this.onAfterSave();
      },

      reset() {
        this.space = {};
      },

      async create() {
        const params = {
          payload: {
            space: {
              address: this.space.address,
            },
          },
        };

        await this.createSpace(params);
      },

      async update() {
        const params = {
          id: this.space.id,
          payload: {
            space: {
              address: this.space.address,
            },
          },
        };

        await this.updateSpace(params);
      },

      async destroy() {
        await this.destroySpace({
          id: this.space.id,
        });

        this.closeSidePanel();
      },
    },
  };
</script>
